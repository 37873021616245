// OmnibarFeature.js

import React, { useEffect } from 'react';

const OmnibarFeature = ({ jwtToken, textAreaContent }) => {
    useEffect(() => {
        if (!jwtToken || !textAreaContent) return;

        const ws = new WebSocket("wss://dev.chromecopilot.ai/ws/omnibar/");
        ws.onopen = () => {
            console.log("Connected to the WebSocket.");
            // Send both jwtToken and the actual message content
            ws.send(JSON.stringify({
                message: textAreaContent,  // This will be used as the 'message' key in your Django consumer
                jwtToken: jwtToken
            }));
        };
        ws.onmessage = (e) => {
            console.log("Message from server:", e.data);
        };
        ws.onerror = (error) => {
            console.error("WebSocket Error:", error);
        };
        ws.onclose = (e) => {
            console.log("WebSocket connection closed:", e.reason);
        };

        return () => ws.close();
    }, [jwtToken, textAreaContent]);

    return null; // This component does not render anything
};

export default OmnibarFeature;
