// Import React and the CSS file
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../helpers/AuthProvider';

import useWindowSize from '../helpers/useWindowSize';
import './LandingPage.css';
import sidebarImage from '../../assets/images/ai-chat.png';
import voiceImage from '../../assets/images/ai-voice.png';
import personalityImage from '../../assets/images/ai-personality.png';
import videoImage from '../../assets/images/ai-video.png';
import searchImage from '../../assets/images/ai-search.png';
import quickActionsImage from '../../assets/images/ai-quick-actions.png';

import copilotImage from '../../assets/images/copilot.png';
import studioImage from '../../assets/images/design-studio-inspo.jpeg';
import nightSkyImage from '../../assets/images/night-sky-sun.png';

import missionImage from '../../assets/images/mission-midjourney.png';
import privacyImage from '../../assets/images/privacy-midjourney.png';
import ethosImage from '../../assets/images/ethos-midjourney3.png';

function LandingPage() {
    // After sign in
    const { user } = useAuth(); // Use the user object from AuthContext
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        if (user) { // If the user object is not null, it means the user is authenticated
            console.log("User is authenticated, navigating to studio...");
            navigate('/studio');
        }
    }, [user, navigate]); // Depend on user and navigate to ensure updates trigger the effect



    const { width } = useWindowSize();

    // Scroll banner
    const [showBanner, setShowBanner] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setShowBanner(true);
        } else {
            setShowBanner(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    // Nav links
    const scrollToSection = (selector, offset = 0) => {
        const element = document.querySelector(selector);
        if (element) {
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - offset;
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            });
        }
    };



    // Carousel
    const items = [
        { title: "Search", body: "AI enhanced search", imgSrc: searchImage },
        { title: "Sidebar", body: "AI for every website", imgSrc: sidebarImage },
        { title: "Voice", body: "6 tutor voices", imgSrc: voiceImage },
        { title: "Personality", body: "4 distinct personalities", imgSrc: personalityImage },
        { title: "Video", body: "AI video processing", imgSrc: videoImage },
        { title: "Quick-actions", body: "A revolution at your fingertips", imgSrc: quickActionsImage }
    ];
    
    const carouselRef = useRef(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);

    const checkScrollPosition = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftButton(scrollLeft > 0);
            setShowRightButton(scrollLeft < scrollWidth - clientWidth);
        }
    };

    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -1000, behavior: 'smooth' });
            setTimeout(checkScrollPosition, 400); // Adjust timing based on scroll behavior
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 1000, behavior: 'smooth' });
            setTimeout(checkScrollPosition, 400); // Adjust timing based on scroll behavior
        }
    };

    // Setup an event listener for the carousel scroll
    useEffect(() => {
        const element = carouselRef.current;
        element.addEventListener('scroll', checkScrollPosition);
        checkScrollPosition(); // Initial check

        return () => element.removeEventListener('scroll', checkScrollPosition);
    }, []);

    // Center carousel on initial load
    useEffect(() => {
        if (carouselRef.current) {
            const { scrollWidth, clientWidth } = carouselRef.current;
            const centerPosition = (scrollWidth - clientWidth) / 2;
            carouselRef.current.scrollLeft = centerPosition;
            checkScrollPosition(); // Update button visibility based on initial scroll position
        }
    }, []);



    // Load in animations
    // Refs for each feature container
    const copilotTextRef = useRef(null);
    const copilotImgRef = useRef(null);
    const studioImgRef = useRef(null);
    const studioTextRef = useRef(null);
    const mindMapTextRef = useRef(null);
    const mindMapImgRef = useRef(null);
    const aboutTitleRef = useRef(null);
    const aboutContainerRef = useRef(null);
    const testimonialTitleRef = useRef(null);
    const testimonial1Ref = useRef(null);
    const testimonial2Ref = useRef(null);
    const testimonial3Ref = useRef(null);
    const testimonial4Ref = useRef(null);
    const testimonial5Ref = useRef(null);
    const testimonial6Ref = useRef(null);
    const testimonial7Ref = useRef(null);
    const testimonial8Ref = useRef(null);
    const testimonialButtonRef = useRef(null);
    const plansTitleRef = useRef(null);
    const novicePlanCardRef = useRef(null);
    const proPlanCardRef = useRef(null);
    const ultraPlanCardRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const delayClass = entry.target.getAttribute('data-animation-delay');
                        entry.target.classList.add('feature-animation', delayClass);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            }
        );
    
        const refs = [copilotTextRef, copilotImgRef, studioImgRef, studioTextRef, mindMapTextRef, mindMapImgRef, aboutTitleRef, testimonialTitleRef, testimonial1Ref, testimonial2Ref, testimonial3Ref, testimonial4Ref, testimonial5Ref, testimonial6Ref, testimonial7Ref, testimonial8Ref, testimonialButtonRef, plansTitleRef, novicePlanCardRef, proPlanCardRef, ultraPlanCardRef];
        refs.forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });
    
        return () => {
            refs.forEach(ref => {
                if (ref.current) observer.unobserve(ref.current);
            });
        };
    }, []);



    // About accordian
    const [openSection, setOpenSection] = useState('mission');
    const missionRef = useRef(null);
    const privacyRef = useRef(null);
    const ethosRef = useRef(null);

    const toggleSection = (section) => {
        // Ensure that the section cannot be closed if it's the only open section
        if (openSection === section) return;
        setOpenSection(section);
    };

    // Determine which image to display based on the open section
    const getImage = () => {
        switch(openSection) {
            case 'mission':
                return missionImage;
            case 'privacy':
                return privacyImage;
            case 'ethos':
                return ethosImage;
            default:
                return missionImage; // Default case to handle unexpected values
        }
    };    
    console.log(width);

    useEffect(() => {
        const handleScroll = () => {
            const container = aboutContainerRef.current;
            if (container) {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const containerTop = container.offsetTop; // Get the top position of the container
                const containerHeight = container.offsetHeight;
    
                // Check if the container top is in the viewport
                if (scrollTop < containerTop + containerHeight) {
                    // Calculate a scaling factor based on scroll position
                    let scale = 1 + (containerTop - scrollTop) / 3000; // Adjust this divisor to control the effect intensity
                    scale = Math.max(scale, 1); // Ensure scale does not go below 1
                    container.style.transform = `scale(${scale})`;
                } else {
                    container.style.transform = 'scale(1)';
                }
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="landing-page">
            <nav className="navbar">
                <div className="LP-navbar-left">
                    <div className="logo-container">
                        <p className="navbar-logo text-gradient">Paradigm</p>
                        <p className="navbar-logo-x">X</p>
                    </div>
                    <div className="nav-links">
                        <a href="#features" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('.copilot-container', window.innerHeight * 0.1);
                        }}>
                            <p>Feature spotlight</p>
                        </a>
                        <a href="#ethos" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('.about-title', window.innerHeight * 0.05);
                        }}>
                            <p>About us</p>
                        </a>
                        <a href="#pricing" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('.plans-section-title', window.innerHeight * 0.05);
                        }}>
                            <p>Plans and pricing</p>
                        </a>
                    </div>
                </div>
                <div className="navbar-right">
                    <a href="#helpcenter">
                        <div className="help-icon-container grey-hover-effect">
                            <i className="bi bi-question-circle"></i>
                            <span className="tooltip">Help center</span>
                        </div>
                    </a>
                    <button className="navbar-button-1 button-secondary" onClick={() => window.location.href = 'https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2'}>
                        Log in
                    </button>
                    <button className="navbar-button-2 button-primary" onClick={() => window.location.href = 'https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2'}>
                        {width < 480 ? 'Log in' : 'Sign up'}
                    </button>
                </div>
            </nav>

            
            <div className="main">
                <div className="title-section">
                    <p className="title">
                        What will you <span className="text-gradient">learn</span> today?
                    </p>
                    <p className="subtitle">
                        Explore and enhance your skills with a personal AI tutor.
                    </p>
                    <button className="fill-available-button main-button button-primary" onClick={() => window.open('https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2')}>
                        Start learning
                    </button>
                </div>

                <div className="carousel-overlay">
                    {showLeftButton && (
                        <div className="scroll-left-button-container" onClick={scrollLeft}>
                            <div className="scroll-button-left"></div>
                        </div>
                    )}
                    {showRightButton && (
                        <div className="scroll-right-button-container" onClick={scrollRight}>
                            <div className="scroll-button-right"></div>
                        </div>
                    )}
                </div>
                <div className="carousel-main-container" ref={carouselRef}>
                    <div className="carousel-inner-container">
                        {items.map((item, index) => (
                            <div className="carousel-item" key={index}
                                style={{
                                    animation: `fadeInUp 1s ease-out ${index * 0.2}s forwards`
                                }}>
                                <div className="carousel-text-container">
                                    <p className="carousel-item-title">{item.title}</p>
                                    <p className="carousel-item-body">{item.body}</p>
                                </div>
                                <img className={item.title.toLowerCase() + "-image"} src={item.imgSrc}></img>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="features-section">
                    <div className="copilot-container">
                        <div ref={copilotTextRef} className='product-text-wrapper' data-animation-delay="delay-0ms">
                            <p className="product-title">Copilot</p>
                            <p className='product-description'>
                                Bring your AI companion with you as you traverse the web. Integrated into your browser for a seamless, one-to-one tutor experience for every website.
                            </p>
                            <button className="product-button button-secondary fill-available-button" onClick={() => window.open('https://chromewebstore.google.com/detail/copilot-your-ai-assistant/kkhpnmhogcfeboepoempiimfbonppocg?hl=en-GB&authuser=0&pli=1')}>
                                Add to Chrome
                            </button>
                        </div>
                        <div ref={copilotImgRef} className="feature-container copilot-feature-container" data-animation-delay="delay-200ms">
                            <img className="copilot-image" src={copilotImage}></img>
                        </div>
                    </div>

                    <div className="studio-container">
                        <div ref={studioImgRef} className="feature-container studio-feature-container" data-animation-delay="delay-0ms">
                            <img className="studio-image" src={studioImage}></img>
                        </div>
                        <div ref={studioTextRef} className='product-text-wrapper' data-animation-delay="delay-200ms">
                            <p className="product-title">Studio</p>
                            <p className='product-description'>
                                Process, extract and find the meaning of any video, lecture, or document. Study, create notes, and auto-generate exercises based on the source material. Let your tutor grade and assist you every step of the way.
                            </p>
                            <button className="product-button button-secondary fill-available-button" onClick={() => window.open('https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2')}>
                                Enter studio
                            </button>
                        </div>
                    </div>

                    <div className="mind-map-container">
                        <div ref={mindMapTextRef}  className='product-text-wrapper' data-animation-delay="delay-0ms">
                            <p className="product-title">Mind map</p>
                            <p className='product-description'>
                                Visualise for the first time the contents of your mind. Each new skill lighting up a new star in your personal learning universe. As you grow, it grows. Each mind map is unqiue to the user and serves as a living mirror to their mind.
                            </p>
                            <button className="product-button button-secondary fill-available-button" onClick={() => window.open('https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2')}>
                                Begin mapping
                            </button>
                        </div>
                        <div ref={mindMapImgRef}  className="feature-container mind-map-feature-container" data-animation-delay="delay-200ms">
                            <img className="mind-map-image" src={nightSkyImage}></img>
                        </div>
                    </div>
                </div>

                <div className="about-section">
                    <p ref={aboutTitleRef} className="about-title" data-animation-delay="delay-0ms">What we stand for.</p>
                    <div ref={aboutContainerRef} className='about-container' data-animation-delay="delay-0ms">
                        <div className='accordian-container'>
                            <div className="mission-section" onClick={() => toggleSection('mission')}
                                style={{
                                    cursor: openSection === 'mission' ? 'default' : 'pointer',
                                }}
                            >
                                <div className="accordian-title-section">
                                    <p className="accordian-title">The mission</p>
                                    <div className={`accordian-arrow ${openSection === 'mission' ? 'open' : ''}`}></div>
                                </div>
                                <div
                                    ref={missionRef}
                                    className={`accordian-content mission-text ${openSection === 'mission' ? 'open' : ''}`}
                                >
                                    <p>
                                        To gameify your personal development to make it as frictionless, satisfying, and effective as possible.
                                    </p>
                                </div>
                            </div>
                            <div className='seperator'></div>
                            <div className="privacy-section" onClick={() => toggleSection('privacy')}
                                style={{
                                    cursor: openSection === 'privacy' ? 'default' : 'pointer',
                                }}
                            >
                                <div className="accordian-title-section">
                                    <p className="accordian-title">Privacy and security</p>
                                    <div className={`accordian-arrow ${openSection === 'privacy' ? 'open' : ''}`}></div>
                                </div>
                                <div
                                    ref={privacyRef}
                                    className={`accordian-content privacy-text ${openSection === 'privacy' ? 'open' : ''}`}
                                >
                                    <p>
                                        Paradigm was designed with your privacy in mind. We are committed to protecting your data and will never sell it to a third party.
                                    </p>
                                </div>
                            </div>
                            <div className='seperator'></div>
                            <div className="ethos-section" onClick={() => toggleSection('ethos')}
                                style={{
                                    cursor: openSection === 'ethos' ? 'default' : 'pointer',
                                }}
                            >
                                <div className="accordian-title-section">
                                    <p className="accordian-title">AI ethos</p>
                                    <div className={`accordian-arrow ${openSection === 'ethos' ? 'open' : ''}`}></div>
                                </div>
                                <div
                                    ref={ethosRef}
                                    className={`accordian-content ethos-text ${openSection === 'ethos' ? 'open' : ''}`}
                                >
                                    <p>
                                        Our AI ethos emphasizes ethical design and transparency in every tool we build. For this reason, we are a community led platform that supports the democratisation of AI.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Display the appropriate image based on the currently open section */}
                        <img className="section-image" src={getImage()} alt="Section Visual" />
                    </div>
                </div>

                <div className="testimonials-section">
                    <p ref={testimonialTitleRef} className="testimonials-title" data-animation-delay="delay-0ms">The Paradigm community.</p>
                    <div className="testimonials-container">
                        {width > 912 ? (
                        // Desktop view: 4 columns
                            <>
                                <div className="testimonials-column">
                                    <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                        </p>
                                    </div>
                                    <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    <div ref={testimonial3Ref} className="testimonial green-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                        </p>
                                    </div>
                                    <div ref={testimonial4Ref} className="testimonial purple-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    <div ref={testimonial5Ref} className="testimonial pink-gradient" data-animation-delay="delay-400ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                        </p>
                                    </div>
                                    <div ref={testimonial6Ref} className="testimonial orange-gradient" data-animation-delay="delay-400ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    <div ref={testimonial7Ref} className="testimonial red-gradient" data-animation-delay="delay-600ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                        </p>
                                    </div>
                                    <div ref={testimonial8Ref} className="testimonial blue-gradient" data-animation-delay="delay-600ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : width > 540 ? (
                            // Tablet view: 3 columns
                            <>
                                <div className="testimonials-column">
                                    {/* 1st Column Testimonials */}
                                    <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                        </p>
                                    </div>
                                    <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                        </p>
                                    </div>
                                    <div ref={testimonial3Ref} className="testimonial red-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    {/* 2nd Column Testimonials */}
                                    <div ref={testimonial4Ref} className="testimonial green-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                        </p>
                                    </div>
                                    <div ref={testimonial5Ref} className="testimonial purple-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                        </p>
                                    </div>
                                    <div ref={testimonial6Ref} className="testimonial blue-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    {/* 3rd Column Testimonials */}
                                    <div ref={testimonial7Ref} className="testimonial pink-gradient" data-animation-delay="delay-400ms">
                                        <div className="testimonial pink-gradient">
                                            <div>
                                                <i className="bi bi-quote"></i>
                                            </div>
                                            <p className="testimonial-text">
                                                This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                            </p>
                                        </div>
                                    </div>
                                    <div ref={testimonial8Ref} className="testimonial orange-gradient" data-animation-delay="delay-400ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            // Mobile view: 2 columns
                            <>
                                <div className="testimonials-column">
                                    <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                        </p>
                                    </div>
                                    <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                        </p>
                                    </div>
                                    <div ref={testimonial3Ref} className="testimonial green-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                        </p>
                                    </div>
                                    <div ref={testimonial4Ref} className="testimonial purple-gradient" data-animation-delay="delay-0ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    <div ref={testimonial5Ref} className="testimonial pink-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                        </p>
                                    </div>
                                    <div ref={testimonial6Ref} className="testimonial orange-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                        </p>
                                    </div>
                                    <div ref={testimonial7Ref} className="testimonial red-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                        </p>
                                    </div>
                                    <div ref={testimonial8Ref} className="testimonial blue-gradient" data-animation-delay="delay-200ms">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div ref={testimonialButtonRef} className="community-button-container" data-animation-delay="delay-0ms">
                        <button className="product-button button-secondary fill-available-button" onClick={() => alert('Login')}>
                            Join the Paradigm community
                        </button>
                    </div>
                </div>

                <div className="plans-section">
                    <p ref={plansTitleRef} className="plans-section-title" data-animation-delay="delay-0ms">
                        A perfect tutor for everyone.
                    </p>
                    <div className="plan-table-container">
                        <div ref={novicePlanCardRef} className="plan-card novice-plan-card" data-animation-delay="delay-0ms">
                            <div className="plan-card-top-section">
                                <div className="plan-text-container">
                                    <p className="plan-title novice-title">Novice</p>
                                    <p className="plan-body">Limited access. If you're new to Paradigm, we recommend starting with the Pro plan's 14 day trial.</p>
                                </div>
                                <div className="price-and-button-container">
                                    <div className="plan-price-container">
                                        <p className="plan-price">£0</p>
                                        <div className="per-month-container">
                                            <p className="per-month-text">per</p>
                                            <p className="per-month-text">month</p>
                                        </div>
                                    </div>
                                    <button className="button-primary plan-button" onClick={() => alert('Novice!')}>
                                        Try Paradigm Novice
                                    </button>
                                </div>
                            </div>
                            <div className="plan-includes-container">
                                <span className="includes-title">Here's what you get each month.</span>
                                <div className="includes-item">
                                    <i className="bi bi-lightning-charge-fill"></i>
                                    <span className="includes-text"> 900 GPT-3.5 messages</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-youtube"></i>
                                    <span className="includes-text"> 15 hours of video processing</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-search"></i>
                                    <span className="includes-text">900 Copilot searches</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-x-circle"></i>
                                    <span className="includes-text">No voice activations</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-x-circle"></i>
                                    <span className="includes-text">No GPT-4 access</span>
                                </div>
                            </div>
                        </div>
                        <div ref={proPlanCardRef} className="plan-card pro-plan-card" data-animation-delay="delay-200ms">
                            <div className="plan-card-top-section">
                                <div className="plan-text-container">
                                    <p className="plan-title pro-title">Pro</p>
                                    <p className="plan-body">Try our full suite of features for free. Cancel anytime. We'll remind you 7 days before your trial ends.</p>
                                </div>
                                <div className="price-and-button-container">
                                    <div className="plan-price-container">
                                        <p className="plan-price">£9.99</p>
                                        <div className="per-month-container">
                                            <p className="per-month-text">per</p>
                                            <p className="per-month-text">month</p>
                                        </div>
                                    </div>
                                    <button className="button-primary plan-button" onClick={() => alert('Pro!')}>
                                        Try free Pro trial
                                    </button>
                                </div>
                            </div>
                            <div className="plan-includes-container">
                                <span className="includes-title">Here's what you get each month.</span>
                                <div className="includes-item">
                                    <i className="bi bi-lightning-charge-fill"></i>
                                    <span className="includes-text"> 30,000 GPT-3.5 messages</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-youtube"></i>
                                    <span className="includes-text"> 500 hours of video processing</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-search"></i>
                                    <span className="includes-text">30,000 Copilot searches</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-volume-up-fill"></i>
                                    <span className="includes-text">600 voice activations</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-stars"></i>
                                    <span className="includes-text">600 GPT-4o access</span>
                                </div>
                            </div>
                        </div>
                        <div ref={ultraPlanCardRef} className="plan-card ultra-plan-card" data-animation-delay="delay-400ms">
                            <div className="plan-card-top-section">
                                <div className="plan-text-container">
                                    <p className="plan-title ultra-title">Ultra</p>
                                    <p className="plan-body">Paradigm at its very best. Maximum power. Maximum productivity.</p>
                                </div>
                                <div className="price-and-button-container">
                                    <div className="plan-price-container">
                                        <p className="plan-price">£24.99</p>
                                        <div className="per-month-container">
                                            <p className="per-month-text">per</p>
                                            <p className="per-month-text">month</p>
                                        </div>
                                    </div>
                                    <button className="button-primary plan-button" onClick={() => alert('Ultra!')}>
                                        Try Paradigm Ultra
                                    </button>
                                </div>
                            </div>
                            <div className="plan-includes-container">
                                <span className="includes-title">Here's what you get each month.</span>
                                <div className="includes-item">
                                    <i className="bi bi-lightning-charge-fill"></i>
                                    <span className="includes-text"> 75,000 GPT-3.5 messages</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-youtube"></i>
                                    <span className="includes-text"> 1,250 hours of video processing</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-search"></i>
                                    <span className="includes-text">75,000 Copilot searches</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-volume-up-fill"></i>
                                    <span className="includes-text">1,500 voice activations</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-stars"></i>
                                    <span className="includes-text">1,500 GPT-4o access</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-section">
                    <div className="footer-seperator"></div>
                    <div className="footer-content-container">
                        <p>© 2024 All Rights Reserved, Paradigm®</p>
                        <div className="terms-container">
                            <p>Privacy policy</p>
                            <p>|</p>
                            <p>Terms of use</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`bottom-banner ${showBanner ? 'show' : 'hide'}`}>
                <p className="bottom-banner-text">The personal tutor you've always wanted.</p>
                <button className="button-primary" onClick={() => alert('Get started!')}>
                    Accept free trial
                </button>
            </div>

        </div>
    );
}

export default LandingPage;