import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../helpers/AuthProvider';
import './Studio.css';
import OmnibarFeature from './OmnibarFeature';

function Studio() {
    const [extensionInstalled, setExtensionInstalled] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [isProActive, setIsProActive] = useState(false);  // State to track toggle state
    const [jwtToken, setJwtToken] = useState(null);
    const [textAreaContent, setTextAreaContent] = useState(null);
    const [ws, setWs] = useState(null); // WebSocket state
    const [isDropdownOpen, setDropdownOpen] = useState(false); // State for managing dropdown open/close
    const omnibarRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        console.log("Setting up WebSocket connection");
        const websocket = new WebSocket("wss://dev.chromecopilot.ai/ws/omnibar/");
        websocket.onopen = () => {
            console.log("Connected to the WebSocket.");
        };
        websocket.onmessage = (e) => {
            console.log("Message from server:", e.data);
        };
        websocket.onerror = (error) => {
            console.error("WebSocket Error:", error);
        };
        websocket.onclose = (e) => {
            console.log("WebSocket connection closed:", e.reason);
        };

        setWs(websocket);

        return () => {
            websocket.close();
            console.log("WebSocket connection closed");
        };
    }, []);

    useEffect(() => {
        console.log("Setting up event listener");
        const handleResponse = (e) => {
            console.log("Received response from extension:", e.detail);
            setExtensionInstalled(true);
        };

        window.addEventListener('copilotExtensionResponse', handleResponse, false);

        return () => {
            window.removeEventListener('copilotExtensionResponse', handleResponse, false);
            console.log("Event listener removed");
        };
    }, []);

    useEffect(() => {
        console.log("Dispatching event to check if extension is installed");
        document.dispatchEvent(new CustomEvent('checkCopilotExtension'));

        const timeout = setTimeout(() => {
            if (extensionInstalled === null) {
                console.log("Assuming no extension installed.");
                setExtensionInstalled(false);
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [extensionInstalled]);

    const { user } = useAuth();  

    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [dropdownRef]);

    const handleSignOut = () => {
        fetch('/api/logout/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Logout failed with status: ' + response.status);
            }
            return response.json();
        })
        .then(data => {
            console.log("Logout response:", data);
            localStorage.removeItem('jwtToken');
            window.location.href = '/';
        })
        .catch(error => {
            console.error('Logout failed:', error);
        });
    };

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
        adjustTextareaHeight();
    };

    const adjustTextareaHeight = () => {
        if (omnibarRef.current) {
            const textarea = omnibarRef.current.querySelector(".search-bar");
            const maxHeight = window.innerHeight * 0.66;
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;

            if (textarea.scrollHeight > maxHeight) {
                omnibarRef.current.style.height = `${maxHeight}px`;
                textarea.style.height = `${maxHeight}px`;
                textarea.style.overflowY = 'scroll';
            } else {
                omnibarRef.current.style.height = 'auto';
                textarea.style.overflowY = 'hidden';
            }
        }
    };

    const handleSendClick = () => {
        const jwt = localStorage.getItem('jwtToken');
        const content = document.querySelector('.search-bar').value;
        setSearchValue(content);  // Assuming you want to store it in state as well
        setJwtToken(jwt);
    
        // Send data over WebSocket
        if (ws && ws.readyState === WebSocket.OPEN) {
            const dataToSend = JSON.stringify({ jwtToken: jwt, message: content });
            ws.send(dataToSend);
            console.log("Sending data to server:", dataToSend);
        } else {
            console.log("WebSocket is not open.");
        }
    };

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    const togglePro = () => {
        setIsProActive(!isProActive);  // Toggle the state of the pro feature
    };

    return (
        <div className="studio-page">
            <nav className="navbar">
                <div className="navbar-left">
                    <div className="burger-container">
                        <i className="bi bi-list"></i>
                    </div>
                    <div className="logo-container">
                        <p className="navbar-logo text-gradient">Paradigm Studio</p>
                        <p className="navbar-logo-x">X</p>
                    </div>
                </div>
                <div className="navbar-center">
                    <div className="mind-map-button-container" tabIndex="0" onClick={() => window.open('https://dev.chromecopilot.ai/mindmap/', '_blank')}>
                        <p className="mind-map-text">
                            Mind Map
                        </p>
                        <i className="bi bi-arrow-up-circle"></i>
                    </div>
                </div>
                <div className="navbar-right">
                    <div className="navbar-right-icons">
                        <a href="#helpcenter">
                            <div className="navbar-icon-container grey-hover-effect">
                                <i className="bi bi-question-circle"></i>
                                <span className="tooltip">Help center</span>
                            </div>
                        </a>
                        <div className="navbar-icon-container grey-hover-effect">
                            <i className="bi bi-gear-wide-connected"></i>
                            <span className="tooltip">Settings</span>
                        </div>
                        <div className="navbar-icon-container grey-hover-effect">
                            <i className="bi bi-bell"></i>
                            <span className="tooltip">Notifications</span>
                        </div>
                    </div>

                    {extensionInstalled === false && (
                        <button className="get-copilot-button button-primary" onClick={() => window.open('https://chromewebstore.google.com/detail/copilot-your-ai-assistant/kkhpnmhogcfeboepoempiimfbonppocg?hl=en-GB&authuser=0&pli=1')}>
                            Get Copilot
                        </button>
                    )}

                    <div className="profile-icon-container" onClick={toggleDropdown} ref={dropdownRef}>
                        <div className="profile-icon">
                            {user ? user.fullName[0] : 'U'}
                            <span className="profile-tooltip">{user ? user.fullName : 'User'}</span>
                        </div>
                        {isDropdownOpen && (
                            <div className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                                <div className="current-profile-section">
                                    <div className="drop-down-profile-icon">
                                        {user ? user.fullName[0] : 'M'}
                                    </div>
                                    <div className="profile-name-container">
                                        <p className="profile-name">
                                            {user ? user.fullName : 'User'}
                                        </p>
                                        <p className="profile-email">
                                            {user ? user.email : 'user@gmail.com'}
                                        </p>
                                    </div>
                                </div>
                                <div className="horizontal-line"></div>
                                <div className="drop-down-options-container">
                                    <div className="drop-down-item">
                                        <i className="bi bi-person"></i>
                                        <p>Profile</p>
                                    </div>
                                    <div className="drop-down-item">
                                        <i className="bi bi-gear-wide-connected"></i>
                                        <p>Settings</p>
                                    </div>
                                    <div className="drop-down-item">
                                        <i className="bi bi-star"></i>
                                        <p>My plan</p>
                                    </div>
                                    <div className="drop-down-item">
                                        <i className="bi bi-question-circle"></i>
                                        <p>Get help</p>
                                    </div>
                                    <div className="drop-down-item">
                                        <i className="bi bi-gift"></i>
                                        <p>Refer a friend</p>
                                    </div>
                                    <div className="drop-down-item">
                                        <i className="bi bi-shield-lock"></i>
                                        <p>Privacy policy</p>
                                    </div>
                                    <div className="drop-down-item">
                                        <i className="bi bi-file-text"></i>
                                        <p>Terms of use</p>
                                    </div>
                                    <div className="drop-down-item" onClick={handleSignOut}>
                                        <i className="bi bi-box-arrow-right"></i>
                                        <p>Sign out</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </nav>

            {/* Main Section */}
            <div className="page">
                {/* Sidebar */}
                <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
                    <div className="sidebar-container">
                        <div className="sidebar-header">
                            <div className="paradigm">Paradigm</div>
                            <div className="collapsible-icon-container" onClick={toggleSidebar}>
                                <i className="bi bi-arrows-collapse-vertical"></i>
                            </div>
                        </div>
                        <div className="user-menu">
                            <div className="menu-item">
                             <i class="bi bi-search"></i> Home
                         </div>
                         <div className="menu-item">
                            <i className="bi bi-collection" style={{ transform: 'rotate(-90deg)' }}></i> Library
                        </div>
                        </div>
                    </div>
                </div>

                {/* Workspace */}
                <div className="workspace-container">
                    <div className="workspace">
                        <div className="search-bar-section">
                            <div className="omnibar-box" ref={omnibarRef}>
                                <textarea 
                                    className="search-bar" 
                                    placeholder="Search..." 
                                    value={searchValue} 
                                    onChange={handleInputChange} 
                                    rows="1"
                                />
                                <div className="grid-container">
                                    <div className="grid-item grid-item1"></div>
                                    <div className="grid-item grid-item2"></div>
                                    <div class="grid-item grid-item3">
                                    <div class="inner-grid">
                                        <div class="inner-grid-item">
                                            <button class="reveal-button">
                                                <div class="icon-text-container">
                                                    <i class="bi bi-filter"></i>
                                                    <span>Focus</span>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="inner-grid-item">
                                            <button class="reveal-button" aria-label="Focus options">
                                                <div class="icon-text-container">
                                                    <i class="bi bi-plus-circle"></i>
                                                    <span>Attach</span>
                                                </div>
                                            </button>
                                        </div>
                                            <div className="inner-grid-item"></div>
                                        </div>
                                    </div>
                                    <div className="grid-item grid-item4"></div>
                                    <div className="grid-item grid-item5">
                                        <div className="flex-container">
                                            <div className="pro-button" onClick={togglePro}>
                                                <div className={`slider ${isProActive ? 'active' : ''}`}></div>
                                            </div>
                                            <div className="pro-button-text">Pro</div>
                                            <button className="omnibar-send-button" onClick={handleSendClick}>
                                                <i className="bi bi-arrow-right-short"></i>
                                            </button>
                                            {jwtToken && textAreaContent && (
                                                <OmnibarFeature jwtToken={jwtToken} textAreaContent={textAreaContent} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Studio;
